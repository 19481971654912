import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Mission,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import RuleImg from '../../../static/png/rule.png';
import Stage5Title from '../../../static/png/stage5/Stage5_title.png';
import CommonBG from '../../uiElements/CommonBG';

interface RuleInstrctionProps {
  stageId: number;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  onStart?: () => void;
  onBack?: () => void;
}

const RuleInstrction: React.FC<RuleInstrctionProps> = props => {
  return (
    <CommonBG>
      <Rule>
        <Title src={Stage5Title} />
        <Mission>
          表示されるお題を、
          <br />
          仲間に的確に伝えなさい。
        </Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 20秒/1問
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>
        <Description>
          <RuleThumb src={RuleImg} />
          <h2>ルール</h2>
          <ul>
            <li>
              1人が<b>「出題者」</b>に、他が<b>「解答者」</b>
              に分かれてプレイします。
            </li>
            <li>
              出題者は表示されたお題を解答者に伝えてください。
              解答者は４つの選択肢の中から、正しいと思うものを選んでください。
            </li>
            <li>
              各問題の制限時間は20秒です。一定の問題を終えると、別の人が出題者に変わります。全員が出題者を終えると終了となります。
            </li>
            <li>
              「解答者として正解した数」と「出題者として正解させられた数」をもとに得点を出します。
              出題者はできるだけ正確にお題を伝え、解答者は言葉を正確に汲み取りましょう。
            </li>
          </ul>
        </Description>

        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => props.onBack?.()}
          >
            戻る
          </Button>
          <Button
            color='positive'
            size='large'
            onClick={() => props.onStart?.()}
          >
            スタート
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const RuleThumb = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: 10px auto;
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  max-width: 800px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

const Description = styled.div`
  padding: 2rem;
  font-size: 1.6rem;

  ul {
    margin-left: 2rem;
  }

  li {
    margin-bottom: 1.4rem;
  }

  b {
    border-bottom: 1px solid black;
  }

  h2 {
    font-size: 2.8rem;
    margin-bottom: 1rem;
  }
`;

export default RuleInstrction;
