import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CommonBG,
  Mission,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { submitScore } from '../../../../api/game';
import {
  Stage5UserDocument,
  useStage5AllSummaries,
} from '../../../../api/stage5';
import {
  calcScore,
  getProblemAndAnswer,
  summaryToAnswer,
} from '../../../../lib/problems/Stage5Score';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStage5GroupInfo,
} from '../../../../redux/selectors/gameSelectors';
import StageTitle from '../../../../static/png/stage5/Stage5_title.png';
import ResultImg from '../../../../static/svg/result.svg';
import { GroupMemberItem } from './GroupInfo';
import { ResultContents, SingleSet } from './ResultContents';

interface ResultPageProps {
  groupDocData?: Stage5UserDocument;
  memberDatas: GroupMemberItem[];
  searchId: number;
  onBack?: () => void;
}

const Result: React.FC<ResultPageProps> = props => {
  const eventId = useEventId();
  const user = useCurrentUser();

  const groupInfo = useStage5GroupInfo();
  const groupId = groupInfo?.groupId ?? null;

  const [summary] = useStage5AllSummaries(eventId, groupId);

  const memberCount = props.memberDatas.length;
  const answer = useMemo(() => {
    if (!summary) return [];
    if (!user) return [];
    return summaryToAnswer(props.memberDatas, summary, user.uid);
  }, [summary]);

  const toTop = () => {
    window.location.href =
      'https://specc-dev.riddler.co.jp/events/' + eventId + '/stages';
  };

  // セット毎に分割した配列に解答を結合する
  const problemAndAnswer: SingleSet[] = React.useMemo(() => {
    return getProblemAndAnswer(answer, memberCount);
  }, [answer, memberCount]);

  // 合計スコアの計算
  const score = React.useMemo(() => {
    return calcScore(problemAndAnswer, memberCount);
  }, [problemAndAnswer, memberCount]);

  React.useEffect(() => {
    if (eventId == null || user == null) return;
    submitScore(eventId, user.uid, score.score);
  }, [score]);

  return (
    <CommonBG>
      <ResultHeader>
        <img src={ResultImg} />
      </ResultHeader>
      <Rule>
        <Title src={StageTitle} />
        <Mission>表示されるお題を、仲間に的確に伝えなさい。</Mission>
        <Time>
          <FontAwesomeIcon icon={faStopwatch} />
          制限時間 20秒/1問
        </Time>
        <VideoWrapper>
          <video controls>
            <source
              src={
                'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4'
              }
              type='video/mp4'
            />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        </VideoWrapper>

        <ResultContents memberCount={memberCount} answer={answer} />

        <Buttons>
          <Button color='negative' size='large' onClick={() => toTop()}>
            ステージ選択へ
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const ResultHeader = styled.div`
  border-bottom: 4px solid #189bf2;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    margin: 2.4rem 0;
    width: 212px;
    height: 32px;
  }
`;

const Time = styled.div`
  background: rgba(206, 212, 218, 0.25);
  font-size: 1.8rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 4px 12px;
  margin-top: -1rem;

  svg {
    margin-right: 4px;
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  margin: 1rem auto;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    margin-bottom: -4px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  width: 45%;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

export default Result;
