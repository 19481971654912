import { gql } from '@apollo/client';
import {
  sendStageEnd,
  sendStageScore,
  sendStageStart,
} from '@riddler-co-jp/specc-auth-client';
import { DocumentReference, doc } from 'firebase/firestore';
import {
  DocumentDataHook,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import { app, db } from '..';

export const eventSchema = gql`
  mutation adminStageReset($input: AdminResetInput!) {
    adminStageReset(input: $input) {
      success
    }
  }
  mutation recordStageStart($input: StartInput!) {
    recordStageStart(input: $input) {
      success
    }
  }
`;

const stageId = 'ct2comm';

export type Stats = {
  averageScore: number;
};
type Maybe<T> = T | null;

export const startGame = (eventId: string, uid: string) =>
  sendStageStart(app, eventId, uid, stageId);

export const submitScore = (eventId: string, uid: string, score: number) =>
  sendStageScore(app, eventId, uid, stageId, score);

export const finishGame = (eventId: string, uid: string) =>
  sendStageEnd(app, eventId, uid, stageId);

export const useStatDocData = (
  eventId?: Maybe<string>
): DocumentDataHook<Stats> => {
  const docRef =
    eventId != null
      ? (doc(db, 'version/1/stats', eventId) as DocumentReference<Stats>)
      : undefined;
  return useDocumentData<Stats>(docRef);
};
