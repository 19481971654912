import { useCTSignIn } from '@riddler-co-jp/specc-auth-client';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { useStartTimesEffect } from '../../api/stages';
import { appActions } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import { app } from '../..';
import Loading from './common/Loading';
import ResultFinal from './stagePages/stage5/ResultFinal';
import Stage5 from './stagePages/stage5/Stage5';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const match = useMatch('/events/:eventId/*');
  useStartTimesEffect();
  const dispatch = useDispatch();

  const [signedIn, signInLoading] = useCTSignIn(
    app,
    'comm',
    match?.params.eventId,
    '2',
    match?.pathname === match?.pathnameBase ||
      match?.pathname === `${match?.pathnameBase}/`
  );

  React.useEffect(() => {
    if (signInLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [signInLoading]);

  if (signInLoading) {
    return <Loading />;
  }
  if (!signInLoading && !signedIn) {
    return <Navigate to='/auth-error' />;
  }

  console.log('EventsRouter');
  return (
    <Routes>
      <Route path='/:eventId' element={<Stage5 />} />
      <Route path='/:eventId/*' element={<Stage5 />} />
      <Route path='/:eventId/result/comm' element={<ResultFinal />} />
    </Routes>
  );
};

export default EventsRouter;
