import React from 'react';

//
import Q01_01_1 from '../../static/jpg/stage5/1-1-1.jpg';
import Q01_01_2 from '../../static/jpg/stage5/1-1-2.jpg';
import Q01_01_3 from '../../static/jpg/stage5/1-1-3.jpg';
import Q01_01_4 from '../../static/jpg/stage5/1-1-4.jpg';
import Q01_02_1 from '../../static/jpg/stage5/1-2-1.jpg';
import Q01_02_2 from '../../static/jpg/stage5/1-2-2.jpg';
import Q01_02_3 from '../../static/jpg/stage5/1-2-3.jpg';
import Q01_02_4 from '../../static/jpg/stage5/1-2-4.jpg';
import Q01_03_1 from '../../static/jpg/stage5/1-3-1.jpg';
import Q01_03_2 from '../../static/jpg/stage5/1-3-2.jpg';
import Q01_03_3 from '../../static/jpg/stage5/1-3-3.jpg';
import Q01_03_4 from '../../static/jpg/stage5/1-3-4.jpg';
import Q01_04_1 from '../../static/jpg/stage5/1-4-1.jpg';
import Q01_04_2 from '../../static/jpg/stage5/1-4-2.jpg';
import Q01_04_3 from '../../static/jpg/stage5/1-4-3.jpg';
import Q01_04_4 from '../../static/jpg/stage5/1-4-4.jpg';
import Q01_05_1 from '../../static/jpg/stage5/1-5-1.jpg';
import Q01_05_2 from '../../static/jpg/stage5/1-5-2.jpg';
import Q01_05_3 from '../../static/jpg/stage5/1-5-3.jpg';
import Q01_05_4 from '../../static/jpg/stage5/1-5-4.jpg';
import Q01_06_1 from '../../static/jpg/stage5/1-6-1.jpg';
import Q01_06_2 from '../../static/jpg/stage5/1-6-2.jpg';
import Q01_06_3 from '../../static/jpg/stage5/1-6-3.jpg';
import Q01_06_4 from '../../static/jpg/stage5/1-6-4.jpg';
//
import Q02_01_1 from '../../static/jpg/stage5/2-1-1.jpg';
import Q02_01_2 from '../../static/jpg/stage5/2-1-2.jpg';
import Q02_01_3 from '../../static/jpg/stage5/2-1-3.jpg';
import Q02_01_4 from '../../static/jpg/stage5/2-1-4.jpg';
import Q02_02_1 from '../../static/jpg/stage5/2-2-1.jpg';
import Q02_02_2 from '../../static/jpg/stage5/2-2-2.jpg';
import Q02_02_3 from '../../static/jpg/stage5/2-2-3.jpg';
import Q02_02_4 from '../../static/jpg/stage5/2-2-4.jpg';
import Q02_03_1 from '../../static/jpg/stage5/2-3-1.jpg';
import Q02_03_2 from '../../static/jpg/stage5/2-3-2.jpg';
import Q02_03_3 from '../../static/jpg/stage5/2-3-3.jpg';
import Q02_03_4 from '../../static/jpg/stage5/2-3-4.jpg';
import Q02_04_1 from '../../static/jpg/stage5/2-4-1.jpg';
import Q02_04_2 from '../../static/jpg/stage5/2-4-2.jpg';
import Q02_04_3 from '../../static/jpg/stage5/2-4-3.jpg';
import Q02_04_4 from '../../static/jpg/stage5/2-4-4.jpg';
import Q02_05_1 from '../../static/jpg/stage5/2-5-1.jpg';
import Q02_05_2 from '../../static/jpg/stage5/2-5-2.jpg';
import Q02_05_3 from '../../static/jpg/stage5/2-5-3.jpg';
import Q02_05_4 from '../../static/jpg/stage5/2-5-4.jpg';
import Q02_06_1 from '../../static/jpg/stage5/2-6-1.jpg';
import Q02_06_2 from '../../static/jpg/stage5/2-6-2.jpg';
import Q02_06_3 from '../../static/jpg/stage5/2-6-3.jpg';
import Q02_06_4 from '../../static/jpg/stage5/2-6-4.jpg';
//
import Q03_01_1 from '../../static/jpg/stage5/3-1-1.jpg';
import Q03_01_2 from '../../static/jpg/stage5/3-1-2.jpg';
import Q03_01_3 from '../../static/jpg/stage5/3-1-3.jpg';
import Q03_01_4 from '../../static/jpg/stage5/3-1-4.jpg';
import Q03_02_1 from '../../static/jpg/stage5/3-2-1.jpg';
import Q03_02_2 from '../../static/jpg/stage5/3-2-2.jpg';
import Q03_02_3 from '../../static/jpg/stage5/3-2-3.jpg';
import Q03_02_4 from '../../static/jpg/stage5/3-2-4.jpg';
import Q03_03_1 from '../../static/jpg/stage5/3-3-1.jpg';
import Q03_03_2 from '../../static/jpg/stage5/3-3-2.jpg';
import Q03_03_3 from '../../static/jpg/stage5/3-3-3.jpg';
import Q03_03_4 from '../../static/jpg/stage5/3-3-4.jpg';
import Q03_04_1 from '../../static/jpg/stage5/3-4-1.jpg';
import Q03_04_2 from '../../static/jpg/stage5/3-4-2.jpg';
import Q03_04_3 from '../../static/jpg/stage5/3-4-3.jpg';
import Q03_04_4 from '../../static/jpg/stage5/3-4-4.jpg';
import Q03_05_1 from '../../static/jpg/stage5/3-5-1.jpg';
import Q03_05_2 from '../../static/jpg/stage5/3-5-2.jpg';
import Q03_05_3 from '../../static/jpg/stage5/3-5-3.jpg';
import Q03_05_4 from '../../static/jpg/stage5/3-5-4.jpg';
import Q03_06_1 from '../../static/jpg/stage5/3-6-1.jpg';
import Q03_06_2 from '../../static/jpg/stage5/3-6-2.jpg';
import Q03_06_3 from '../../static/jpg/stage5/3-6-3.jpg';
import Q03_06_4 from '../../static/jpg/stage5/3-6-4.jpg';

// ３人用、４人用の、出題者一人当たりの出題問題数（１セットの問題数）
export const NUM_OF_QUESTIONS_THREE = 6;
export const NUM_OF_QUESTIONS_FOUR = 4;

// 1つ目が出題者に表示される＝正解
// 3人用
export const stage5problems: [string, string, string, string, string][] = [
  [Q01_01_1, Q01_01_1, Q01_01_2, Q01_01_3, Q01_01_4], // 1
  [Q01_02_4, Q01_02_1, Q01_02_2, Q01_02_3, Q01_02_4], // 2
  [Q01_03_1, Q01_03_1, Q01_03_2, Q01_03_3, Q01_03_4], // 3
  [Q01_04_3, Q01_04_1, Q01_04_2, Q01_04_3, Q01_04_4], // 4
  [Q01_05_2, Q01_05_1, Q01_05_2, Q01_05_3, Q01_05_4], // 5
  [Q01_06_2, Q01_06_1, Q01_06_2, Q01_06_3, Q01_06_4], // 6

  [Q02_01_3, Q02_01_1, Q02_01_2, Q02_01_3, Q02_01_4], // 7
  [Q02_02_2, Q02_02_1, Q02_02_2, Q02_02_3, Q02_02_4], // 8
  [Q02_03_1, Q02_03_1, Q02_03_2, Q02_03_3, Q02_03_4], // 9
  [Q02_04_3, Q02_04_1, Q02_04_2, Q02_04_3, Q02_04_4], // 10
  [Q02_05_4, Q02_05_1, Q02_05_2, Q02_05_3, Q02_05_4], // 11
  [Q02_06_2, Q02_06_1, Q02_06_2, Q02_06_3, Q02_06_4], // 12

  [Q03_01_3, Q03_01_1, Q03_01_2, Q03_01_3, Q03_01_4], // 13
  [Q03_02_1, Q03_02_1, Q03_02_2, Q03_02_3, Q03_02_4], // 14
  [Q03_03_4, Q03_03_1, Q03_03_2, Q03_03_3, Q03_03_4], // 15
  [Q03_04_4, Q03_04_1, Q03_04_2, Q03_04_3, Q03_04_4], // 16
  [Q03_05_4, Q03_05_1, Q03_05_2, Q03_05_3, Q03_05_4], // 17
  [Q03_06_2, Q03_06_1, Q03_06_2, Q03_06_3, Q03_06_4], // 18
];

// 正解＝上記1つ目の末尾の数字
export const stage5Answers = [
  1, // 1
  4, // 2
  1, // 3
  3, // 4
  2, // 5
  2, // 6

  3, // 7
  2, // 8
  1, // 9
  3, // 10
  4, // 11
  2, // 12

  3, // 13
  1, // 14
  4, // 15
  4, // 16
  4, // 17
  2, // 18
];

// 4人用の場合の問題出題順
export const problemForFour = [
  1, 3, 4, 6, 7, 9, 10, 12, 13, 15, 16, 17, 2, 5, 18, 11,
] as const;

interface PresenterText {
  main: string;
  sub?: string;
}
interface Stage5TextItem {
  presenter: PresenterText;
  answerer: string;
}

export const stage5Texts: Stage5TextItem[] = [
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 1
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 2
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 3
  {
    presenter: {
      main: '文字の字体を説明してください。 ',
      sub: '※相手の選択肢は、文字の字体のみ異なります。',
    },
    answerer: '相手が見ている文字は？',
  }, // 4
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 5
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 6
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 7
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 8
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 9
  {
    presenter: { main: '画像を説明してください。' },
    answerer: '相手が見ている絵は？',
  }, // 10
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 11
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 12
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 13
  {
    presenter: { main: '言葉の意味を説明してください。' },
    answerer: '相手が伝えている言葉は？',
  }, // 14
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 15
  {
    presenter: { main: '画像を説明してください。' },
    answerer: '相手が見ている図は？',
  }, // 16
  {
    presenter: { main: '写真を説明してください。' },
    answerer: '相手が見ている写真は？',
  }, // 17
  {
    presenter: { main: '画像を説明してください。' },
    answerer: '相手が見ている画像は？',
  }, // 18
];

export interface Stage5Problem {
  index: number;
  count: number;
  text: Stage5TextItem;
  imageSrc: [string, string, string, string, string];
  answer: number;
}

export const useStage5Problem = (
  problemId: number,
  problemCount: number
): Stage5Problem => {
  const problemIndex = problemId - 1;
  return React.useMemo<Stage5Problem>(
    () => ({
      index: problemIndex,
      count: problemCount,
      imageSrc: stage5problems[problemIndex],
      text: stage5Texts[problemIndex],
      answer: stage5Answers[problemIndex],
    }),
    [problemIndex, problemCount]
  );
};
