import {
  Mission,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { useStatDocData } from '../../../../api/game';
import {
  useMemberDatas,
  useStage5AllSummaries,
  useStage5FinishedGroupsWithUid,
} from '../../../../api/stage5';
import {
  calcScore,
  getProblemAndAnswer,
  summaryToAnswer,
} from '../../../../lib/problems/Stage5Score';
import { calcRankMessage } from '../../../../lib/stageResults';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import StageTitle from '../../../../static/png/stage5/Stage5_title.png';
import CommonBG from '../../../uiElements/CommonBG';
import { ResultContents, SingleSet } from './ResultContents';

interface ResultPageProps {
  onBack?: () => void;
}

const ResultFinal: React.FC<ResultPageProps> = props => {
  const eventId = useEventId();
  const user = useCurrentUser();

  console.log(eventId, user?.uid ?? null);
  const [groupDocData, groupDocDataLoading, error] =
    useStage5FinishedGroupsWithUid(eventId, user?.uid ?? null);

  const groupInfo = useMemo(
    () =>
      groupDocData?.[0].searchId && groupDocData?.[0].groupDocId
        ? {
            searchId: groupDocData?.[0].searchId,
            groupId: groupDocData?.[0].groupDocId,
          }
        : null,
    [groupDocData?.[0].searchId, groupDocData?.[0].groupDocId]
  );
  const { memberDatas, onReloadGroupMembers } = useMemberDatas(
    groupDocData?.[0],
    groupInfo,
    eventId
  );
  const [summary] = useStage5AllSummaries(
    eventId,
    groupDocData?.[0].groupDocId ?? null
  );

  const memberCount = useMemo(() => memberDatas.length, [memberDatas.length]);
  const answer = useMemo(() => {
    if (!summary) return [];
    if (!user?.uid) return [];
    if (!memberDatas) return [];
    return summaryToAnswer(memberDatas, summary, user.uid);
  }, [summary, memberDatas, user?.uid]);

  // セット毎に分割した配列に解答を結合する
  const problemAndAnswer: SingleSet[] = React.useMemo(() => {
    return getProblemAndAnswer(answer, memberCount);
  }, [answer, memberCount]);

  // 合計スコアの計算
  const score = React.useMemo(() => {
    return calcScore(problemAndAnswer, memberCount);
  }, [problemAndAnswer, memberCount]);

  const rank = React.useMemo(() => calcRankMessage(score.score, 4), [score]);

  const [stat, loadStat, errorStat] = useStatDocData(eventId);
  const average = React.useMemo(() => {
    return stat?.averageScore ?? 0;
  }, [stat]);
  const description =
    '情報の整理と論理的な構築が得意のようです。今回は「すべて正しい情報」をもとに思考できましたが、実際の仕事では「より正しい情報」を見抜くことも重要になります。常に情報の確かさを意識しながら仕事をすると、より正しい選択をできるようになるはずです。';
  // ここを変更してください /////////////////////////////////////////////

  return (
    <CommonBG withFooter>
      <Rule>
        <Title src={StageTitle} />
        <Mission>表示されるお題を、仲間に的確に伝えなさい。</Mission>

        <ResultContents
          memberCount={memberCount}
          answer={answer}
          description={description}
          rank={rank}
          average={average}
        />

        <Buttons>
          <Button
            color='negative'
            size='large'
            onClick={() => {
              const historyUrl =
                'https://specc-dev.riddler.co.jp/history/' + eventId;
              location.href = historyUrl;
            }}
          >
            戻る
          </Button>
        </Buttons>
      </Rule>
    </CommonBG>
  );
};

const ResultHeader = styled.div`
  border-bottom: 4px solid #189bf2;
  margin: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    margin: 2.4rem 0;
    width: 212px;
    height: 32px;
  }
`;

const Title = styled.img`
  display: block;
  height: 80px;
  width: auto;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  max-width: 47%;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Buttons = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  margin: 0 auto 10rem;
  margin-top: 2rem;
`;

const Rule = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 6rem auto;
  margin-top: 0;
  display: block;
`;

export default ResultFinal;
