import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';

import { useRecordStageStartMutation } from '../../../api/__generated__/game.generated';
import { useServerTimeOffsetEffect } from '../../../api/common';
import { startGame } from '../../../api/game';
import { StageId } from '../../../api/stages';
import { isStageDebug } from '../../../lib/env';
import useLocalStorage from '../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../redux/actions/gameActions';
import { timeActions } from '../../../redux/actions/timeActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import {
  useEventId,
  useRecordedStartTime,
  useStageManagerState,
  useStageState,
} from '../../../redux/selectors/gameSelectors';
import { useRemainingTime } from '../../../redux/selectors/timeSelectors';
import { EventStatus } from '../../../types';
import CountDown from './CountDown';
import Finished from './Finished';
import RuleInstrction from './RuleInstruction';

interface StageManagerProps {
  stageId: StageId;
  stageTitleImgSrc?: string;
  ruleImgSrc?: string;
  finishOnTimeUp?: boolean;
  noCountDown?: boolean;
  noRecordStart?: boolean;
}

export enum StageManagerPhase {
  INSTRUCTION,
  COUNTDOWN,
  CONTENT,
}

export interface StageManagerState {
  phase: StageManagerPhase;
  startButtonActive: boolean;
}

export const STAGE_TIME_LIMIT = 365;

const StageManager: React.FC<StageManagerProps> = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const match = useMatch('/events/:eventId');
  const startTime = useRecordedStartTime(props.stageId);
  const remainingTime = useRemainingTime();
  const stageState = useStageState({ stageId: props.stageId });
  const eventId = useEventId();
  const managerState = useStageManagerState();

  // TODO: スクロール完了処理
  const { startButtonActive } = managerState;
  const showingInstruction =
    !isStageDebug && managerState.phase === StageManagerPhase.INSTRUCTION;
  const showingCountDown =
    !isStageDebug && managerState.phase === StageManagerPhase.COUNTDOWN;
  const finished = false;
  /*
    (!isStageDebug && stageState.finished) ||
    initEvent?.status == EventStatus.Ended;
    */

  const setPhase = React.useCallback(
    (phase: StageManagerPhase) => {
      dispatch(gameActions.setStageManagerState({ phase }));
    },
    [dispatch]
  );

  React.useEffect(() => {
    setPhase(StageManagerPhase.INSTRUCTION);
  }, [setPhase]);

  const onRecordStageStartCompleted = React.useCallback(() => {
    if (props.noCountDown) {
      setPhase(StageManagerPhase.CONTENT);
    } else {
      setPhase(StageManagerPhase.COUNTDOWN);
    }
  }, [props.noCountDown, setPhase]);

  const [recordStageStart] = useRetryableMutationWithUI(
    useRecordStageStartMutation,
    {
      hookOptions: { onCompleted: onRecordStageStartCompleted },
      loading: { options: { text: 'ステージを開始しています...' } },
      error: {
        options: {
          errorType: 'CommonError',
          message: 'ステージの開始中にエラーが発生しました。',
        },
      },
    }
  );

  useServerTimeOffsetEffect();
  React.useEffect(() => {
    if (startTime !== undefined && !props.noRecordStart && !showingCountDown) {
      setPhase(StageManagerPhase.CONTENT);
      dispatch(
        timeActions.setTimer({
          startTimeOnServer: startTime,
          duration: STAGE_TIME_LIMIT,
        })
      );
    }
  }, [dispatch, startTime, props.noRecordStart, setPhase, showingCountDown]);

  React.useEffect(() => {
    dispatch(
      gameActions.setStageManagerState({
        startButtonActive: true,
      })
    );
  }, [dispatch]);

  const user = useCurrentUser();
  const [, setStageStatus] = useLocalStorage<string | null>(
    'stage' + props.stageId + ':' + eventId + ':' + user?.uid,
    null
  );

  React.useEffect(() => {
    if (remainingTime === 0 && props.finishOnTimeUp) {
      setStageStatus('finished');
      dispatch(
        gameActions.setHasStageFinished({
          stage: props.stageId,
          finished: true,
        })
      );
      dispatch(timeActions.clearTimer());
    }
  }, [
    dispatch,
    remainingTime,
    props.finishOnTimeUp,
    props.stageId,
    setStageStatus,
  ]);

  const onBackToTop = React.useCallback(() => {
    return;
  }, []);

  const onStart = React.useCallback(() => {
    if (eventId === null) return;
    if (user === null) return;

    if (!startButtonActive) return;

    if (props.noRecordStart) {
      onRecordStageStartCompleted();
      return;
    }

    if (startTime === undefined) {
      recordStageStart({
        variables: { input: { eventId, stageId: '' + props.stageId } },
      });
      startGame(eventId, user.uid);
    }
  }, [
    user,
    eventId,
    startButtonActive,
    props.noRecordStart,
    props.stageId,
    startTime,
    onRecordStageStartCompleted,
    recordStageStart,
  ]);

  const onBack = React.useCallback(() => {
    window.location.href =
      'https://specc-dev.riddler.co.jp/events/' + eventId + '/stages';
  }, [eventId]);

  const onCountdownEnd = React.useCallback(() => {
    setPhase(StageManagerPhase.CONTENT);
    dispatch(timeActions.updateRemainingTime());
  }, [dispatch, setPhase]);

  return showingInstruction ? (
    <RuleInstrction {...props} onStart={onStart} onBack={onBack} />
  ) : showingCountDown ? (
    <CountDown onEnd={onCountdownEnd} />
  ) : (
    /*finished ? (
    <Finished stage={props.stageId} onBackToTop={onBackToTop} />
  ) : */ <>{props.children}</>
  );
};

export default StageManager;
